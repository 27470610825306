import React, { useState, useRef } from 'react';
import { 
  Plus, 
  Send, 
  Image, 
  File, 
  Smile, 
  Paperclip,
  Video,
  Music,
  X
} from 'lucide-react';
import EmojiPicker from './EmojiPicker'; // Make sure to import the EmojiPicker component

const MessageInput = ({ 
  newMessage, 
  setNewMessage, 
  handleSendMessage, 
  handleFileUpload, 
  attachments, 
  handleRemoveAttachment, 
  error, 
  setError, 
  isLoading, 
  isDragging,
  // Add these new props for emoji functionality
  showEmojiPicker,
  setShowEmojiPicker,
  handleEmojiSelect
}) => {
  const [showInputOptions, setShowInputOptions] = useState(false);
  const messageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  
  // Handle key press (Enter to send)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  // Handle file input change
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file);
      
      // Reset the input to allow selecting the same file again
      e.target.value = '';
      setShowInputOptions(false);
    }
  };

  // Handle image input change
  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if it's an image
      if (!file.type.startsWith('image/')) {
        setError("Please select a valid image file (JPEG, PNG, GIF, etc.)");
        return;
      }
      
      handleFileUpload(file);
      
      // Reset the input to allow selecting the same file again
      e.target.value = '';
      setShowInputOptions(false);
    }
  };
  
  // Handle video input change
  const handleVideoInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if it's a video
      if (!file.type.startsWith('video/')) {
        setError("Please select a valid video file");
        return;
      }
      
      handleFileUpload(file);
      
      // Reset the input to allow selecting the same file again
      e.target.value = '';
      setShowInputOptions(false);
    }
  };
  
  return (
    <div className="relative">
      {/* Error display for file uploads */}
      {error && (
        <div className="px-3 py-2 bg-red-50 text-red-800 text-sm flex items-start border-b border-red-200">
          <div className="flex-1">
            <p className="font-medium">Error</p>
            <p className="text-xs">{error}</p>
          </div>
          <button 
            onClick={() => setError('')}
            className="ml-auto text-red-600 hover:text-red-800"
          >
            <X size={16} />
          </button>
        </div>
      )}

      {/* Attachments Preview */}
      {attachments.length > 0 && (
        <div className="px-3 py-2 bg-gray-100 border-b border-gray-200">
          <div className="flex flex-wrap gap-2">
            {attachments.map((attachment, index) => (
              <div 
                key={index}
                className="bg-white rounded p-2 border flex items-center"
              >
                {attachment.type?.startsWith('image/') ? (
                  <Image size={16} className="text-blue-500 mr-2" />
                ) : attachment.type?.startsWith('video/') ? (
                  <Video size={16} className="text-red-500 mr-2" />
                ) : (
                  <File size={16} className="text-blue-500 mr-2" />
                )}
                <span className="text-sm truncate max-w-[150px]">{attachment.name}</span>
                <button 
                  onClick={() => handleRemoveAttachment(index)}
                  className="ml-2 text-gray-400 hover:text-red-500"
                >
                  <X size={14} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* Input Options (vertical menu) */}
      {showInputOptions && (
        <div className="absolute bottom-full mb-2 left-0 bg-white rounded-lg shadow-lg border overflow-hidden z-10">
          <div className="p-1 space-y-1">
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => fileInputRef.current?.click()}
              disabled={isLoading}
              title="Attach file"
            >
              <Paperclip size={18} className="mr-2" />
              <span className="text-sm">File</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => imageInputRef.current?.click()}
              disabled={isLoading}
              title="Attach image"
            >
              <Image size={18} className="mr-2" />
              <span className="text-sm">Image</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => videoInputRef.current?.click()}
              disabled={isLoading}
              title="Attach video"
            >
              <Video size={18} className="mr-2" />
              <span className="text-sm">Video</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker);
                setShowInputOptions(false);
              }}
              title="Add emoji"
            >
              <Smile size={18} className="mr-2" />
              <span className="text-sm">Emoji</span>
            </button>
          </div>
          
          {/* Hidden input fields */}
          <input 
            type="file" 
            ref={fileInputRef}
            className="hidden" 
            onChange={handleFileInputChange}
            disabled={isLoading}
          />
          <input 
            type="file" 
            ref={imageInputRef}
            className="hidden" 
            accept="image/*"
            onChange={handleImageInputChange}
            disabled={isLoading}
          />
          <input 
            type="file" 
            ref={videoInputRef}
            className="hidden" 
            accept="video/*"
            onChange={handleVideoInputChange}
            disabled={isLoading}
          />
        </div>
      )}
      
      {/* Emoji Picker */}
      {showEmojiPicker && (
        <div className="absolute bottom-full right-0 mb-2">
          <EmojiPicker 
            onSelect={handleEmojiSelect}
            onClickOutside={() => setShowEmojiPicker(false)}
          />
        </div>
      )}
      
      {/* Input Area */}
      <div className="flex items-start p-2">
        <button 
          className={`mr-2 p-2 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded-full ${showInputOptions ? 'text-blue-600 bg-blue-50' : ''}`}
          onClick={() => setShowInputOptions(!showInputOptions)}
          disabled={isLoading}
        >
          <Plus size={22} />
        </button>
        
        <div className="flex-1 relative">
          <textarea
            ref={messageInputRef}
            className="w-full bg-gray-50 border rounded-lg px-3 py-2 overflow-y-auto text-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none min-h-[60px] max-h-[150px]"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            rows={1}
            style={{
              height: newMessage.split('\n').length > 2 ? 'auto' : '40px'
            }}
          ></textarea>
        </div>
        
        <button 
          className="ml-1 bg-[#1d67cd] text-white p-2 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
          onClick={handleSendMessage}
          disabled={(!newMessage.trim() && attachments.length === 0) || isLoading}
        >
          <Send size={18} />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;