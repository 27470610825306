// src/components/communication/AIChat.jsx
import React, { useState, useEffect, useRef } from 'react';
import { 
  Send, MessageSquare, ChevronLeft, Search, 
  Plus, Calendar, Clock, FileText, ChevronDown,
  AlertCircle, BarChart2, Edit, Trash2, Check, X,
  MoreVertical
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import TimeSelector from './TimeSelector';
import DataSourceSelector from './DataSourceSelector';

const AIChat = () => {
  const { currentUser, userProfile } = useAuth();
  const [conversations, setConversations] = useState([
    { id: 'demo-1', title: 'New Conversation', updatedAt: new Date() }
  ]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [error, setError] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Conversation editing states
  const [editingId, setEditingId] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  
  // Time and data source selection
  const [timePeriod, setTimePeriod] = useState('today');
  const [dataSources, setDataSources] = useState([]);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showDataSourcePicker, setShowDataSourcePicker] = useState(false);
  
  const messageEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const optionsRef = useRef(null);

  // Detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
      // Close sidebar by default on mobile
      if (window.innerWidth < 768) {
        setSidebarOpen(false);
      }
    };
    
    // Check on initial load
    checkIfMobile();
    
    // Listen for resize events
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Handle clicks outside options menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(null);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Select a conversation
  const handleSelectConversation = (conversation) => {
    setActiveConversation(conversation);
    setMessages(conversation.messages || []);
    
    // Close sidebar on mobile
    if (isMobile) {
      setSidebarOpen(false);
    }
  };

  // Create a new conversation
  const createNewConversation = () => {
    const newConversation = {
      id: `demo-${Date.now()}`,
      title: "New Conversation",
      messages: [],
      createdAt: new Date(),
      updatedAt: new Date()
    };
    
    setConversations(prev => [newConversation, ...prev]);
    setActiveConversation(newConversation);
    setMessages([]);
    
    // Focus on message input
    setTimeout(() => {
      messageInputRef.current?.focus();
    }, 100);
  };

  // Start editing a conversation title
  const startEditing = (conversation) => {
    setEditingId(conversation.id);
    setEditTitle(conversation.title);
    setShowOptions(null);
  };

  // Cancel editing
  const cancelEditing = () => {
    setEditingId(null);
    setEditTitle('');
  };

  // Save the edited title
  const saveTitle = (conversationId) => {
    if (!editTitle.trim()) return;
    
    // Update the conversation title in state
    setConversations(prev => 
      prev.map(conv => 
        conv.id === conversationId 
          ? { ...conv, title: editTitle.trim() } 
          : conv
      )
    );
    
    // Update active conversation if this is the one being edited
    if (activeConversation?.id === conversationId) {
      setActiveConversation(prev => ({
        ...prev,
        title: editTitle.trim()
      }));
    }
    
    setEditingId(null);
  };

  // Delete a conversation
  const deleteConversation = (conversationId) => {
    if (window.confirm("Are you sure you want to delete this conversation?")) {
      // Remove from conversations array
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      
      // If we deleted the active conversation, reset it
      if (activeConversation?.id === conversationId) {
        setActiveConversation(null);
        setMessages([]);
      }
      
      setShowOptions(null);
    }
  };

  // Toggle options menu for a conversation
  const toggleOptions = (conversationId) => {
    setShowOptions(showOptions === conversationId ? null : conversationId);
  };

  // Send message
  const handleSendMessage = () => {
    if (!newMessage.trim() || !activeConversation) return;
    
    // Format time display
    const timeContext = timePeriod.replace('_', ' ');
    const dataContext = dataSources.length > 0 
      ? dataSources.join(', ') 
      : 'all available sources';
    
    // Add user message to the conversation
    const userMessage = {
      role: 'user',
      content: newMessage,
      timestamp: new Date(),
      timePeriod,
      dataSources: [...dataSources]
    };
    
    // Create updated messages array
    const updatedMessages = [...messages, userMessage];
    
    // Update local state for immediate feedback
    setMessages(updatedMessages);
    
    // Update the conversation object with the new messages
    const updatedConversation = {
      ...activeConversation,
      messages: updatedMessages,
      updatedAt: new Date()
    };
    
    // If this is the first message, set the title
    if (updatedMessages.length === 1) {
      updatedConversation.title = generateTitle(newMessage);
    }
    
    // Update active conversation
    setActiveConversation(updatedConversation);
    
    // Update the conversations list
    setConversations(prev => 
      prev.map(conv => 
        conv.id === activeConversation.id ? updatedConversation : conv
      )
    );
    
    // Clear the input
    setNewMessage('');
    
    // Scroll to bottom
    setTimeout(() => {
      messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
    
    // Simulate AI response (in a real app, this would call an API)
    setIsLoading(true);
    
    setTimeout(() => {
      const aiResponse = {
        role: 'assistant',
        content: generateDemoResponse(newMessage),
        timestamp: new Date()
      };
      
      // Add AI response to messages
      const messagesWithAI = [...updatedMessages, aiResponse];
      setMessages(messagesWithAI);
      
      // Update conversation with AI response
      const finalConversation = {
        ...updatedConversation,
        messages: messagesWithAI,
        updatedAt: new Date()
      };
      
      setActiveConversation(finalConversation);
      
      // Update the conversations list
      setConversations(prev => 
        prev.map(conv => 
          conv.id === activeConversation.id ? finalConversation : conv
        )
      );
      
      setIsLoading(false);
      
      // Scroll to bottom
      setTimeout(() => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }, 1500);
  };

  // Generate a title from the first message
  const generateTitle = (message) => {
    const maxLength = 30;
    return message.length > maxLength
      ? message.substring(0, maxLength) + '...'
      : message;
  };

  // Generate a demo response
  const generateDemoResponse = (message) => {
    const responses = [
      "Based on the business data from last month, sales have increased by 15% compared to the previous period.",
      "I've analyzed your inventory data and noticed you're running low on several key products. Would you like me to generate a reorder report?",
      "According to financial trends over the last quarter, your main expense categories have shifted significantly. Marketing expenses have increased while operational costs decreased.",
      "Your customer engagement metrics show a positive trend on weekends, but there's a notable drop in activity on Wednesdays.",
      "I've detected a potential issue with your cash flow projections for next month based on current accounts receivable aging.",
      "Based on historical data, your busy season is approaching. Would you like me to help prepare inventory and staffing recommendations?"
    ];
    
    return responses[Math.floor(Math.random() * responses.length)];
  };

  // Handle key press (Enter to send)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Format timestamp
  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Format date safely
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'New chat';
    
    try {
      const date = timestamp instanceof Date ? 
        timestamp : 
        (timestamp.toDate ? timestamp.toDate() : new Date(timestamp));
      
      return date.toLocaleDateString();
    } catch (error) {
      return 'New chat';
    }
  };

  return (
    <div className="flex h-[calc(100vh-150px)] overflow-hidden">
      {/* Sidebar */}
      <div className={`fixed md:relative h-full w-80 flex flex-col bg-[#190192] text-white z-30
                    transition-transform duration-300 ease-in-out
                    ${isMobile && !sidebarOpen ? '-translate-x-full' : 'translate-x-0'}`}>
        {/* Sidebar Header - Fixed */}
        <div className="flex-shrink-0 p-4 border-b border-[#ffffff33]">
          <h2 className="text-lg font-semibold">Data Insights</h2>
        </div>
        
        {/* New Chat Button - Fixed */}
        <div className="flex-shrink-0 p-4 border-b border-[#ffffff33]">
          <button
            onClick={createNewConversation}
            className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-[#190192] bg-white hover:bg-gray-100 focus:outline-none"
          >
            <Plus className="h-4 w-4 mr-1" />
            New Chat
          </button>
        </div>
        
        {/* Conversations List - Scrollable */}
        <div className="flex-1 overflow-y-auto py-2">
          <ul className="space-y-1 px-2">
            {conversations.map((conversation) => (
              <li key={conversation.id} className="relative">
                {editingId === conversation.id ? (
                  // Editing mode
                  <div className="px-3 py-2 rounded-md bg-[#3d7dd6]">
                    <input
                      type="text"
                      value={editTitle}
                      onChange={(e) => setEditTitle(e.target.value)}
                      className="w-full px-2 py-1 text-sm bg-white text-gray-800 rounded border-0 focus:outline-none focus:ring-2 focus:ring-white mb-2"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') saveTitle(conversation.id);
                        if (e.key === 'Escape') cancelEditing();
                      }}
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={cancelEditing}
                        className="text-white hover:text-red-200 p-1"
                        title="Cancel"
                      >
                        <X size={14} />
                      </button>
                      <button
                        onClick={() => saveTitle(conversation.id)}
                        className="text-white hover:text-green-200 p-1"
                        title="Save"
                      >
                        <Check size={14} />
                      </button>
                    </div>
                  </div>
                ) : (
                  // Normal display mode
                  <div className="flex items-center">
                    <button
                      onClick={() => handleSelectConversation(conversation)}
                      className={`flex-1 text-left px-3 py-2 rounded-md flex items-center overflow-hidden ${
                        activeConversation?.id === conversation.id
                          ? 'bg-[#ffffff33] text-white'
                          : 'text-blue-100 hover:bg-[#ffffff22] hover:text-white'
                      }`}
                    >
                      <MessageSquare className="h-5 w-5 mr-2 flex-shrink-0" />
                      <div className="overflow-hidden w-full">
                        <p className="truncate text-sm font-medium w-full">
                          {conversation.title || "New Conversation"}
                        </p>
                        <p className="text-xs text-blue-200 truncate w-full">
                          {formatTimestamp(conversation.updatedAt)}
                        </p>
                      </div>
                    </button>
                    
                    {/* Options button */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleOptions(conversation.id);
                      }}
                      className={`p-1.5 rounded-full ml-1 flex-shrink-0 ${
                        showOptions === conversation.id 
                          ? 'bg-[#3d7dd6] text-white' 
                          : 'text-blue-200 hover:bg-[#2d6dc6] hover:text-white'
                      }`}
                    >
                      <MoreVertical size={14} />
                    </button>
                    
                    {/* Options menu */}
                    {showOptions === conversation.id && (
                      <div 
                        ref={optionsRef}
                        className="absolute right-2 z-10 mt-2 w-36 rounded-md shadow-lg bg-white" 
                        style={{ top: '100%' }}
                      >
                        <div className="py-1">
                          <button
                            onClick={() => startEditing(conversation)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                          >
                            <Edit size={14} className="mr-2 text-gray-500" />
                            Rename
                          </button>
                          <button
                            onClick={() => deleteConversation(conversation.id)}
                            className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center"
                          >
                            <Trash2 size={14} className="mr-2 text-red-500" />
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Main Content */}
      <div className={`flex-1 flex flex-col h-full ${isMobile ? (sidebarOpen ? 'ml-80' : '0') : ''}`}
           style={{ width: isMobile ? '100%' : 'calc(100% - 320px)', marginLeft: isMobile ? 0 : 'auto' }}>
        
        {/* Mobile Sidebar Toggle */}
        {isMobile && !sidebarOpen && (
          <button 
            onClick={() => setSidebarOpen(true)}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-20 bg-[#190192] text-white p-2 rounded-r-md shadow-md"
          >
            <ChevronLeft size={20} />
          </button>
        )}
        
        {/* Mobile Sidebar Overlay */}
        {sidebarOpen && isMobile && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-20"
            onClick={() => setSidebarOpen(false)}
          ></div>
        )}
        
        {activeConversation ? (
          <>
            {/* Chat Header - Fixed */}
            <div className="flex-shrink-0 h-14 border-b flex items-center justify-between px-4 bg-white z-10">
              <div className="flex items-center">
                {isMobile && !sidebarOpen && (
                  <button onClick={() => setSidebarOpen(true)} className="mr-2">
                    <ChevronLeft size={20} />
                  </button>
                )}
                <h2 className="text-lg font-medium text-gray-800">
                  {activeConversation.title || "New Conversation"}
                </h2>
              </div>
            </div>
            
            {/* Messages Container - Scrollable */}
            <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
              {messages.length === 0 ? (
                <div className="flex-1 flex flex-col items-center justify-center py-12">
                  <BarChart2 className="h-12 w-12 text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-700 mb-2">Ask about your business data</h3>
                  <p className="text-gray-500 mb-6 text-center max-w-md">
                    Select a time period and data sources, then ask questions to gain insights
                  </p>
                  
                  <div className="w-full max-w-md px-4">
                    <div className="bg-white shadow-sm rounded-md p-4 mb-4">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">Example questions:</h4>
                      <ul className="space-y-2 text-gray-600 text-sm">
                        <li className="cursor-pointer hover:text-blue-600" onClick={() => setNewMessage("What were the most reported incidents last week?")}>
                          • What were the most reported incidents last week?
                        </li>
                        <li className="cursor-pointer hover:text-blue-600" onClick={() => setNewMessage("Show me student attendance trends for the previous month")}>
                          • Show me student attendance trends for the previous month
                        </li>
                        <li className="cursor-pointer hover:text-blue-600" onClick={() => setNewMessage("What challenges were reported yesterday?")}>
                          • What challenges were reported yesterday?
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  {messages.map((message, index) => (
                    <div 
                      key={index} 
                      className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div 
                        className={`max-w-3xl rounded-lg px-4 py-2 ${
                          message.role === 'user' 
                            ? 'bg-[#190192] text-white' 
                            : 'bg-white border border-gray-200 text-gray-800'
                        }`}
                      >
                        <div className="text-sm">{message.content}</div>
                        <div 
                          className={`text-xs mt-1 ${
                            message.role === 'user' ? 'text-purple-200' : 'text-gray-500'
                          }`}
                        >
                          {formatTime(message.timestamp)}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div ref={messageEndRef} />
                </div>
              )}
            </div>
            
            {/* Input Area - Fixed */}
            <div className="flex-shrink-0 relative border-t bg-white p-4">
              {/* Selection controls for time and data sources */}
              <div className="mb-2 flex justify-between">
                <div className="flex space-x-2">
                  {/* Time selector */}
                  <div className="relative">
                    <button 
                      className={`inline-flex items-center px-3 py-1 border ${timePeriod ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                      onClick={() => setShowTimePicker(!showTimePicker)}
                    >
                      <Calendar className="h-4 w-4 mr-1" />
                      {timePeriod.replace('_', ' ') || 'Select time'}
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </button>
                    
                    {showTimePicker && (
                      <TimeSelector 
                        onSelect={(period) => {
                          setTimePeriod(period);
                          setShowTimePicker(false);
                        }}
                        onClose={() => setShowTimePicker(false)}
                        // Position above the button
                        position="top"
                      />
                    )}
                  </div>
                  
                  {/* Data source selector */}
                  <div className="relative">
                    <button 
                      className={`inline-flex items-center px-3 py-1 border ${dataSources.length > 0 ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                      onClick={() => setShowDataSourcePicker(!showDataSourcePicker)}
                    >
                      <FileText className="h-4 w-4 mr-1" />
                      {dataSources.length > 0 ? `${dataSources.length} sources` : 'Data sources'}
                      <ChevronDown className="h-4 w-4 ml-1" />
                    </button>
                    
                    {showDataSourcePicker && (
                      <DataSourceSelector 
                        selected={dataSources}
                        onSelect={setDataSources}
                        onClose={() => setShowDataSourcePicker(false)}
                        // Position above the button
                        position="top"
                      />
                    )}
                  </div>
                </div>
              </div>
              
              {error && (
                <div className="mb-2 p-2 bg-red-50 border-l-4 border-red-500 text-red-700 text-sm">
                  <AlertCircle size={16} className="inline-block mr-1" />
                  {error}
                </div>
              )}
              
              <div className="flex rounded-md shadow-sm">
                <textarea
                  ref={messageInputRef}
                  className="flex-1 min-h-[60px] max-h-32 focus:ring-[#190192] focus:border-[#190192] block w-full rounded-l-md sm:text-sm border-gray-300 resize-none py-2 px-4"
                  placeholder="Ask about your business data..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  disabled={isLoading}
                />
                <button
                  type="button"
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white ${
                    !newMessage.trim() || isLoading 
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-[#190192] hover:bg-[#2C0DB5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#190192]'
                  }`}
                  onClick={handleSendMessage}
                  disabled={!newMessage.trim() || isLoading}
                >
                  {isLoading ? (
                    <div className="h-5 w-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  ) : (
                    <Send className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          // Empty state when no conversation is selected
          <div className="flex-1 flex flex-col items-center justify-center bg-gray-100 p-6">
            <BarChart2 className="h-16 w-16 text-gray-400 mb-4" />
            <h2 className="text-2xl font-bold text-gray-700 mb-2">AI Data Insights</h2>
            <p className="text-gray-500 mb-6 text-center max-w-md">
              Ask questions about your data to gain insights on activity, progress, and challenges
            </p>
            <button
              onClick={createNewConversation}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#190192] hover:bg-[#2C0DB5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#190192]"
            >
              <Plus className="h-4 w-4 mr-2" />
              Start a New Chat
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIChat;