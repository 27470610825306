// src/pages/CommunicationPage.js
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MessageSquare, Mail, Bot } from 'lucide-react';

const CommunicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(getActiveTab());

  function getActiveTab() {
    const path = location.pathname.split('/')[2] || 'internal';
    return path;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/communication/${tab}`);
  };

  return (
    <div className="h-full flex flex-col">
      {/* Tabs */}
      <div className="bg-white shadow-sm flex-shrink-0">
        <nav className="flex space-x-8 px-6" aria-label="Tabs">
          <button
            onClick={() => handleTabChange('internal')}
            className={`py-4 px-1 inline-flex items-center font-medium text-sm ${
              activeTab === 'internal'
                ? 'border-[#190192] text-[#190192]'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <MessageSquare className="h-5 w-5 mr-2" />
            Internal Messaging
          </button>
          <button
            onClick={() => handleTabChange('external')}
            className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
              activeTab === 'external'
                ? 'border-[#190192] text-[#190192]'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Mail className="h-5 w-5 mr-2" />
            External Messaging
          </button>
          <button
            onClick={() => handleTabChange('ai')}
            className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
              activeTab === 'ai'
                ? 'border-[#190192] text-[#190192]'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Bot className="h-5 w-5 mr-2" />
            AI Chat
          </button>
        </nav>
      </div>

      {/* Tab Content - allow vertical overflow */}
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
};

export default CommunicationPage;