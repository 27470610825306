// src/components/communication/InternalMessaging.js
// src/components/communication/InternalMessaging.js - Add fileInputRef declaration
import React, { useState, useEffect, useRef } from 'react';
import { 
  MessageSquare, Users, Search, Plus, Paperclip, Send, MoreHorizontal, 
  Image, File, FileText, Video, Music, Upload, AlertCircle, Trash2,
  Smile, X, User, Menu, ChevronRight, ChevronLeft
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, query, where, orderBy, onSnapshot, addDoc, 
  doc, updateDoc, arrayUnion, Timestamp, getDoc, getDocs, writeBatch, limit
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Import sub-components
import ChannelList from './ChannelList';
import DirectMessageList from './DirectMessageList';
import ChannelMembers from './ChannelMembers';
import MessageList from './MessageList';
import EmojiPicker from './EmojiPicker';
import MessageInput from './MessageInput';
import MediaModal from './MediaModal';

const InternalMessaging = () => {
  const { currentUser, userProfile } = useAuth();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState(null);
  const [activeChannelData, setActiveChannelData] = useState(null);
  const [isDirectMessage, setIsDirectMessage] = useState(false);
  const [channels, setChannels] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showChannelMembers, setShowChannelMembers] = useState(false);
  const [selectedChannelForMembers, setSelectedChannelForMembers] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [inputWidth, setInputWidth] = useState('100%');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaItems, setMediaItems] = useState([]);

  const mainContentRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const messageInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null); 
  const messageContainerRef = useRef(null);

  

  const isUserChannelMember = (channelData) => {
    if (!channelData || !currentUser) return false;
    const members = channelData.members || [];
    return members.includes(currentUser.uid);
  };

  const handleManageChannelMembers = (channelId) => {
    setSelectedChannelForMembers(channelId);
    setShowChannelMembers(true);
  };
  // Detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Check on initial load
    checkIfMobile();
    
    // Listen for resize events
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Get color for user initial
  const getColorForInitial = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red'];
    const charCode = initial ? initial.charCodeAt(0) : 0;
    return colors[charCode % colors.length];
  };

  const handleRemoveAttachment = (index) => {
    setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
  };

  // Fetch Channels
  useEffect(() => {
    if (!userProfile?.id || !userProfile?.businessId) {
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    let unsubscribe = () => {};
    
    try {
      // Get channels the user has access to
      const channelsRef = collection(db, "channels");
      const q = query(
        channelsRef,
        where("businessId", "==", userProfile.businessId),
        orderBy("createdAt", "desc") // Order by creation time, newest first
      );
      
      // Set up real-time listener
      unsubscribe = onSnapshot(q, (snapshot) => {
        console.log("Channels snapshot received, count:", snapshot.docs.length);
        
        const channelList = [];
        snapshot.forEach((doc) => {
          // Calculate unread count - in a real app this would be more sophisticated
          const unreadCount = Math.floor(Math.random() * 15); // Just for demo
          
          channelList.push({
            id: doc.id,
            ...doc.data(),
            unread: unreadCount
          });
        });
        
        console.log("Processed channels:", channelList.map(c => c.name));
        setChannels(channelList);
        
        // If we don't have channel data yet, set the active channel
        if (channelList.length > 0 && !activeChannelData) {
          const defaultChannel = channelList.find(c => c.id === 'general') || channelList[0];
          setActiveChannel(defaultChannel.id);
          setActiveChannelData(defaultChannel);
          setIsDirectMessage(false);
        }
        
        setIsLoading(false);
      }, (error) => {
        console.error("Error fetching channels:", error);
        setError("Failed to load channels. Please try again.");
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error setting up channel listener:", error);
      setError("Failed to initialize channel listener.");
      setIsLoading(false);
    }
    
    return () => unsubscribe();
  }, [userProfile]);

  // Fetch Direct Messages
  useEffect(() => {
    if (!userProfile?.id) return;
    
    let unsubscribe = () => {};
    
    try {
      // Get user's direct message conversations
      const conversationsRef = collection(db, "conversations");
      const q = query(
        conversationsRef,
        where("participants", "array-contains", userProfile.id)
      );
      
      unsubscribe = onSnapshot(q, async (snapshot) => {
        // Use a map to ensure uniqueness by conversation ID
        const dmMap = new Map();
        
        // Process each conversation
        for (const docSnapshot of snapshot.docs) {
          const conversation = docSnapshot.data();
          const conversationId = docSnapshot.id;
          const otherParticipantId = conversation.participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            try {
              // Only fetch from userProfiles collection
              const userProfileDoc = await getDoc(doc(db, "userProfiles", otherParticipantId));
              
              if (userProfileDoc.exists()) {
                const userData = userProfileDoc.data();
                const unreadCount = conversation.unreadBy && conversation.unreadBy[userProfile.id] 
                  ? conversation.unreadBy[userProfile.id] 
                  : 0;
                
                // Store in map using conversation ID as key
                dmMap.set(conversationId, {
                  id: conversationId,
                  userId: otherParticipantId,
                  name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown User',
                  avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
                  color: getColorForInitial(userData.firstName?.charAt(0) || 'A'),
                  status: userData.status || 'offline',
                  unread: unreadCount
                });
              } else {
                console.log("User profile not found, skipping:", otherParticipantId);
              }
            } catch (error) {
              console.error("Error fetching user details:", error);
            }
          }
        }
        
        // Convert map to array
        const dmList = Array.from(dmMap.values());
        setDirectMessages(dmList);
      });
    } catch (error) {
      console.error("Error setting up direct message listener:", error);
    }
    
    return () => unsubscribe();
  }, [userProfile]);

  // Fetch Messages for Active Channel/DM
  useEffect(() => {
    if (!activeChannel) return;
    
    // Clear current messages
    setMessages([]);
    
    let messagesRef;
    let q;
    
    if (isDirectMessage) {
      // Direct messages
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("conversationId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    } else {
      // Channel messages
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("channelId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    }
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageList = [];
      snapshot.forEach((doc) => {
        messageList.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setMessages(messageList);
      
      // Mark as read if direct message
      if (isDirectMessage && activeChannelData) {
        updateUnreadMessages(activeChannel);
      }
      
      // Scroll to bottom
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }, (error) => {
      console.error("Error fetching messages:", error);
      setError("Failed to load messages. Please try again.");
    });
    
    return unsubscribe;
  }, [activeChannel, isDirectMessage, activeChannelData]);

  useEffect(() => {
    const handleResize = () => {
      if (messageContainerRef.current) {
        // Get dimensions of the input area to adjust message container correctly
        const inputHeight = dropAreaRef.current ? dropAreaRef.current.offsetHeight : 80;
        
        // Set the padding-bottom of the message container
        messageContainerRef.current.style.paddingBottom = `${inputHeight + 20}px`;
      }
    };
  
    // Run initially and after any state changes that might affect the input height
    handleResize();
    
    // Add event listeners
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [isMobile, attachments.length]); 

  useEffect(() => {
    const updateInputWidth = () => {
      if (mainContentRef.current && !isMobile) {
        // For desktop: width of main content minus sidebar width
        setInputWidth(`${mainContentRef.current.offsetWidth}px`);
      } else if (isMobile) {
        // For mobile: full width with adjustment for sidebar
        setInputWidth('100%');
      }
    };
    
    updateInputWidth();
    window.addEventListener('resize', updateInputWidth);
    return () => window.removeEventListener('resize', updateInputWidth);
  }, [isMobile, mobileSidebarOpen]);

  // Update unread messages count
  const updateUnreadMessages = async (conversationId) => {
    if (!userProfile?.id) return;
    
    try {
      const conversationRef = doc(db, "conversations", conversationId);
      await updateDoc(conversationRef, {
        [`unreadBy.${userProfile.id}`]: 0
      });
    } catch (error) {
      console.error("Error updating unread messages:", error);
    }
  };

  // Handle channel change
  const handleChannelChange = (channelId) => {
    const selectedChannel = channels.find(c => c.id === channelId);
    if (selectedChannel) {
      setActiveChannel(channelId);
      setActiveChannelData(selectedChannel);
      setIsDirectMessage(false);
    }
  };

  // Handle direct message selection
  const handleDirectMessageSelect = async (userId) => {
    setIsLoading(true);
    setError('');
    
    try {
      console.log("Selecting user or conversation:", userId);
      
      // First check if this is an existing conversation
      const existingDM = directMessages.find(dm => dm.id === userId);
      
      if (existingDM) {
        console.log("Found existing conversation:", existingDM);
        // This is an existing conversation ID
        
        // Update with latest user data from userProfiles
        try {
          const otherUserId = existingDM.userId;
          const userProfileDoc = await getDoc(doc(db, "userProfiles", otherUserId));
          
          if (userProfileDoc.exists()) {
            const updatedUserData = userProfileDoc.data();
            console.log("Updated user profile data:", updatedUserData);
            
            // Create updated channel data with latest user info
            const updatedDM = {
              ...existingDM,
              name: `${updatedUserData.firstName || ''} ${updatedUserData.lastName || ''}`.trim() || existingDM.name,
              avatar: `${updatedUserData.firstName?.charAt(0) || ''}${updatedUserData.lastName?.charAt(0) || '?'}`
            };
            
            // Update in directMessages array
            setDirectMessages(prev => 
              prev.map(dm => dm.id === userId ? updatedDM : dm)
            );
            
            // Set as active channel with updated data
            setActiveChannel(userId);
            setActiveChannelData(updatedDM);
            setIsDirectMessage(true);
          } else {
            // If user profile not found, just use existing data
            setActiveChannel(userId);
            setActiveChannelData(existingDM);
            setIsDirectMessage(true);
          }
        } catch (profileError) {
          console.error("Error updating user profile data:", profileError);
          // Continue with existing data
          setActiveChannel(userId);
          setActiveChannelData(existingDM);
          setIsDirectMessage(true);
        }
        
        // Load messages for this conversation
        try {
          const messagesRef = collection(db, "messages");
          const q = query(
            messagesRef,
            where("conversationId", "==", userId),
            orderBy("timestamp", "asc")
          );
          
          const querySnapshot = await getDocs(q);
          const messagesList = [];
          
          querySnapshot.forEach((doc) => {
            messagesList.push({
              id: doc.id,
              ...doc.data()
            });
          });
          
          setMessages(messagesList);
          
          // Mark as read
          updateUnreadMessages(userId);
        } catch (msgError) {
          console.error("Error loading messages:", msgError);
          setError("Failed to load messages");
        }
      } else {
        console.log("Creating new conversation with user:", userId);
        // Create a new conversation with this user
        
        // Get user details from userProfiles collection
        try {
          const userProfileDoc = await getDoc(doc(db, "userProfiles", userId));
          
          if (!userProfileDoc.exists()) {
            console.error("User profile does not exist:", userId);
            setError("User profile not found");
            setIsLoading(false);
            return;
          }
          
          const userData = userProfileDoc.data();
          console.log("Found user profile data:", userData);
          
          // Create a new conversation
          const conversationData = {
            participants: [currentUser.uid, userId],
            createdAt: new Date(),
            lastMessageTimestamp: new Date(),
            lastMessage: '',
            unreadBy: {
              [currentUser.uid]: 0,
              [userId]: 0
            }
          };
          
          console.log("Creating conversation with data:", conversationData);
          console.log("Current user ID:", currentUser.uid);
          console.log("Participant IDs:", conversationData.participants);
          
          const docRef = await addDoc(collection(db, "conversations"), conversationData);
          console.log("Created conversation with ID:", docRef.id);
          
          // Create active channel data for UI
          const newDM = {
            id: docRef.id,
            userId: userId,
            name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || "Unknown User",
            avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
            status: userData.status || 'offline',
            unread: 0
          };
          
          setActiveChannel(docRef.id);
          setActiveChannelData(newDM);
          setIsDirectMessage(true);
          setMessages([]); // No messages yet
          
          // Also add this to our directMessages list
          setDirectMessages(prev => [newDM, ...prev]);
        } catch (userError) {
          console.error("Error fetching user profile or creating conversation:", userError);
          setError("Failed to create conversation");
        }
      }
      
      // Focus the message input
      if (messageInputRef.current) {
        setTimeout(() => {
          messageInputRef.current.focus();
        }, 300);
      }
      
    } catch (error) {
      console.error("Error selecting direct message:", error);
      setError("Failed to load conversation");
    } finally {
      setIsLoading(false);
      
      // Close mobile sidebar if on mobile
      if (isMobile) {
        setMobileSidebarOpen(false);
      }
    }
  };

  // Send message
  const handleSendMessage = async () => {
    if ((!newMessage.trim() && attachments.length === 0) || !activeChannel || !userProfile) return;
    
    try {
      // Create message object
      const messageData = {
        content: newMessage.trim(),
        senderId: userProfile.id,
        senderName: `${userProfile.firstName} ${userProfile.lastName}`.trim(),
        senderInitials: `${userProfile.firstName?.charAt(0) || ''}${userProfile.lastName?.charAt(0) || ''}`,
        timestamp: new Date(),
        ...(isDirectMessage 
          ? { conversationId: activeChannel } 
          : { channelId: activeChannel }),
        pinned: false
      };
      
      // Add attachments if any
      if (attachments.length > 0) {
        messageData.attachments = attachments.map(attachment => ({
          name: attachment.name,
          url: attachment.url,
          type: attachment.type,
          size: attachment.size
        }));
      }
      
      // Add message to Firestore
      await addDoc(collection(db, "messages"), messageData);
      
      // If it's a DM, update the conversation with unread message
      if (isDirectMessage) {
        const conversationRef = doc(db, "conversations", activeChannel);
        const conversationDoc = await getDoc(conversationRef);
        
        if (conversationDoc.exists()) {
          const otherParticipantId = conversationDoc.data().participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            await updateDoc(conversationRef, {
              lastMessage: newMessage.trim() || 'Attachment',
              lastMessageTimestamp: new Date(),
              [`unreadBy.${otherParticipantId}`]: (conversationDoc.data().unreadBy?.[otherParticipantId] || 0) + 1
            });
          }
        }
      }
      
      // Clear message and attachments
      setNewMessage('');
      setAttachments([]);
      setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message. Please try again.");
    }
  };

  // Handle file upload
  const handleFileUpload = async (file) => {
    if (!file || !activeChannel) return;
    
    try {
      setError('');
      
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setError("File is too large. Maximum size is 10MB.");
        return;
      }
      
      // Create a unique filename to prevent collisions
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
      
      // Determine the correct path based on channel type
      const storagePath = isDirectMessage 
        ? `messages/direct/${activeChannel}/${uniqueFileName}`
        : `messages/channels/${activeChannel}/${uniqueFileName}`;
      
      // Upload to Firebase Storage
      const storageRef = ref(storage, storagePath);
      
      // Upload the file
      const uploadTask = uploadBytes(storageRef, file);
      const snapshot = await uploadTask;
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Add to attachments
      const newAttachment = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        size: file.size,
        path: storagePath
      };
      
      setAttachments(prev => [...prev, newAttachment]);
      return newAttachment;
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(`Failed to upload file: ${error.message}`);
      return null;
    }
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji) => {
    setNewMessage(prev => prev + emoji);
    setShowEmojiPicker(false);
  };

  const handleAddReaction = async (messageId, emoji) => {
    if (!messageId || !emoji || !userProfile?.id) return;
    try {
      const messageRef = doc(db, "messages", messageId);
      await updateDoc(messageRef, {
        [`reactions.${emoji}`]: arrayUnion(userProfile.id)
      });
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
  };

  const handleOpenMedia = (media, allMediaItems = [], index = 0) => {
    setSelectedMedia(media);
    setMediaItems(allMediaItems);
    setMediaIndex(index);
    setMediaModalOpen(true);
  };

  const handleCloseMedia = () => {
    setMediaModalOpen(false);
    setSelectedMedia(null);
  };

  const handleNextMedia = () => {
    if (mediaIndex < mediaItems.length - 1) {
      setMediaIndex(mediaIndex + 1);
      setSelectedMedia(mediaItems[mediaIndex + 1]);
    }
  };
  
  // Navigate to previous media
  const handlePreviousMedia = () => {
    if (mediaIndex > 0) {
      setMediaIndex(mediaIndex - 1);
      setSelectedMedia(mediaItems[mediaIndex - 1]);
    }
  };

  const handleDeleteConversation = async (conversationId) => {
    // Show confirmation dialog
    if (!window.confirm("Are you sure you want to delete this conversation? This cannot be undone.")) {
      return;
    }
    
    try {
      setIsLoading(true);
      
      console.log("Deleting conversation:", conversationId);
      
      // Delete all messages from this conversation first
      const messagesRef = collection(db, "messages");
      const messagesQuery = query(messagesRef, where("conversationId", "==", conversationId));
      const messageSnapshots = await getDocs(messagesQuery);
      
      const deleteBatch = writeBatch(db);
      
      // Add message deletes to batch
      messageSnapshots.forEach((doc) => {
        deleteBatch.delete(doc.ref);
      });
      
      // Add conversation delete to batch
      deleteBatch.delete(doc(db, "conversations", conversationId));
      
      // Execute the batch
      await deleteBatch.commit();
      
      console.log("Conversation and messages deleted successfully");
      
      // Update local state - remove the conversation
      setDirectMessages(prev => prev.filter(dm => dm.id !== conversationId));
      
      // Remove any locally stored data related to the conversation
      localStorage.removeItem(`conversation_${conversationId}`);
      sessionStorage.removeItem(`conversation_${conversationId}`);
      
      // If this was the active conversation, reset the active conversation
      if (activeChannel === conversationId) {
        setActiveChannel(null);
        setActiveChannelData(null);
        setIsDirectMessage(false);
        setMessages([]);
      }
      
      // Show success message
      setError(""); // Clear any previous errors
      
    } catch (error) {
      console.error("Error deleting conversation:", error);
      setError("Failed to delete conversation. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex overflow-hidden h-[calc(100vh-150px)]">
      {/* Sidebar - Fixed position, with scrollable content */}
      <div className={`fixed md:relative h-full w-80 flex flex-col bg-[#190192] text-white z-30
                      transition-transform duration-300 ease-in-out
                      ${isMobile && !mobileSidebarOpen ? '-translate-x-full' : 'translate-x-0'}`}>
        {/* Sidebar Header - Fixed */}
        <div className="flex-shrink-0 p-4 border-b border-[#ffffff33]">
          <div className="relative">
            <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#190192]" />
            <input 
              type="text" 
              placeholder="Search messages..." 
              className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none text-sm bg-white text-gray-800" 
            />
          </div>
        </div>
        
        {/* Sidebar Content - Scrollable */}
        <div className="flex-1 overflow-y-auto">
          <ChannelList 
            channels={channels}
            activeChannel={activeChannel}
            onChannelSelect={handleChannelChange}
            isDirectMessage={isDirectMessage}
          />
          
          <DirectMessageList 
            directMessages={directMessages}
            activeChannel={activeChannel}
            onDirectMessageSelect={handleDirectMessageSelect}
            isDirectMessage={isDirectMessage}
            getColorForInitial={getColorForInitial}
          />
        </div>
      </div>

      {/* Main Content Area - Takes remaining width */}
      <div className={`flex-1 flex flex-col h-[calc(100vh-150px)] ${isMobile ? (mobileSidebarOpen ? 'ml-80' : '0') : ''}`}
           style={{ width: isMobile ? '100%' : 'calc(100% - 320px)', marginLeft: isMobile ? 0 : 'auto' }}>
        
        {/* Channel Header - Fixed */}
        <div className="flex-shrink-0 h-14 border-b flex items-center justify-between px-4 bg-white z-10">
          <div className="flex items-center">
            {isMobile && !mobileSidebarOpen && (
              <button onClick={() => setMobileSidebarOpen(true)} className="mr-2">
                <Menu size={20} />
              </button>
            )}
            <h2 className="text-lg font-semibold text-[#190192] mr-2">
              {isDirectMessage 
                ? activeChannelData?.name 
                : `#${activeChannelData?.name || 'Loading...'}`}
            </h2>
            <span className="text-sm text-gray-500">
              {(!isDirectMessage && activeChannelData?.membersCount) 
                ? `${activeChannelData.membersCount} members` 
                : ''}
            </span>
          </div>
          
          <div className="flex items-center space-x-3">
            {!isDirectMessage && activeChannelData && (
              <button
                onClick={() => handleManageChannelMembers(activeChannel)}
                className="text-gray-500 hover:text-gray-700"
                title="Manage channel members"
              >
                <Users size={20} />
              </button>
            )}
            
            <button className="text-gray-500 hover:text-gray-700">
              <MoreHorizontal size={20} />
            </button>
          </div>
        </div>
        
        {/* Messages Container - Takes remaining height with scrollable content */}
        <div className="flex-1 relative">
          <div className="absolute inset-0 overflow-y-auto" ref={messageContainerRef}>
            {/* Check if user can view messages */}
            {!isDirectMessage && activeChannelData && !isUserChannelMember(activeChannelData) ? (
              <div className="flex items-center justify-center h-full bg-gray-50">
                <div className="text-center p-6 max-w-md mx-auto bg-white shadow-md rounded-lg">
                  <Users className="h-12 w-12 text-blue-500 mx-auto mb-4" />
                  <h3 className="text-xl font-medium text-gray-900 mb-2">You're not a member of this channel</h3>
                  <p className="text-gray-600 mb-6">You need to join this channel to view messages and participate in discussions.</p>
                  <button
                    onClick={() => handleManageChannelMembers(activeChannel)}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    Join Channel
                  </button>
                </div>
              </div>
            ) : (
              <div className="p-4 pb-2"> {/* Add padding at bottom to make space for input */}
                <MessageList 
                  messages={messages}
                  userProfile={userProfile}
                  getColorForInitial={getColorForInitial}
                  messagesEndRef={messagesEndRef}
                  isLoading={isLoading}
                  onAddReaction={handleAddReaction}
                  onOpenMedia={handleOpenMedia}
                />
              </div>
            )}
          </div>
          
          {/* Input Area - Fixed at bottom */}
          <div 
            className="absolute bottom-0 left-0 right-0 bg-white border-t shadow-lg"
            ref={dropAreaRef}
          >
            {isDragging && (
              <div className="absolute inset-0 bg-blue-50 border-2 border-dashed border-blue-300 flex items-center justify-center z-20">
                <div className="text-blue-500 text-center">
                  <Upload size={40} className="mx-auto mb-2" />
                  <p className="font-medium">Drop files to upload</p>
                </div>
              </div>
            )}
            
            {/* Error display for file uploads */}
            {error && (
              <div className="px-3 py-2 bg-red-50 text-red-600 text-sm">
                <AlertCircle size={16} className="inline-block mr-1" />
                {error}
              </div>
            )}
            
            <MessageInput 
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              handleFileUpload={handleFileUpload}
              attachments={attachments}
              handleRemoveAttachment={handleRemoveAttachment}
              error={error}
              setError={setError}
              isLoading={isLoading}
              isDragging={isDragging}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
              handleEmojiSelect={handleEmojiSelect}
            />
          </div>
        </div>
      </div>
      
      {/* Modals */}
      {showChannelMembers && selectedChannelForMembers && (
        <ChannelMembers
          channelId={selectedChannelForMembers}
          isOpen={showChannelMembers}
          onClose={() => {
            setShowChannelMembers(false);
            setSelectedChannelForMembers(null);
          }}
        />
      )}
      
      {mediaModalOpen && selectedMedia && (
        <MediaModal
          media={selectedMedia}
          onClose={handleCloseMedia}
          onNext={handleNextMedia}
          onPrevious={handlePreviousMedia}
          hasNext={mediaIndex < mediaItems.length - 1}
          hasPrevious={mediaIndex > 0}
        />
      )}
      
      {/* Mobile Sidebar Toggle */}
      {isMobile && !mobileSidebarOpen && (
        <button 
          onClick={() => setMobileSidebarOpen(true)}
          className="fixed left-0 top-1/2 transform -translate-y-1/2 z-20 bg-[#190192] text-white p-2 rounded-r-md shadow-md"
        >
          <ChevronRight size={20} />
        </button>
      )}
      
      {/* Mobile Sidebar Overlay */}
      {mobileSidebarOpen && isMobile && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setMobileSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default InternalMessaging;